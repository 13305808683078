<template>
  <v-navigation-drawer
    :model-value="modelValue"
    v-bind="safeAttrs"
    class="pb-16"
    @update:model-value="$emit('update:modelValue')"
  >
    <slot name="default">
      <!--injected component goes here-->
    </slot>

    <v-toolbar
      absolute
      class="position-bottom"
      color="transparent"
      elevation="4"
    >
      <slot name="actions">
        <!--injected actions goes here-->
      </slot>
    </v-toolbar>
  </v-navigation-drawer>
</template>

<script>
import { defaults } from 'lodash-es';
import { useDisplay } from 'vuetify';

export default defineComponent({
  name: 'MtfDrawer',
  inheritAttrs: true,
  customOptions: {},
  props: {
    modelValue: Boolean
  },
  emits: ['update:modelValue'],
  computed: {
    safeAttrs() {
      const { width, mobile } = useDisplay();

      return defaults(
        { ...this.$attrs },
        {
          app: false,
          fixed: true,
          temporary: true,
          location: 'right',
          width: mobile.value ? width.value : 480
        }
      );
    }
  }
});
</script>

<style lang="scss" scoped>
.position-top {
  top: 0 !important;
}

.position-bottom {
  bottom: 0 !important;
}
</style>
